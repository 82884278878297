import { Typography, Container, Stack, Grid, Card, CardContent } from "@mui/material";
import React from "react";
import SimulatorWidget from "shared/simulator-widget";
import Footer from "../../partials/footer";
import News from "./news";
import { Hero, PageSection, ProfilePicture, SectionTitle } from "./styled";

const Homepage = (props) => {
  return (
    <>
      <Hero>
        <Container>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} md={6}>
              <Typography variant="h2"><b>Faça a sua simulação de crédito habitação, e descubra o seu potencial de compra agora!</b></Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <SimulatorWidget />
            </Grid>
          </Grid>
        </Container>
      </Hero>
      <PageSection>
        <Container>
          <Stack alignItems="center">
            <SectionTitle variant="h4" color="primary"><b>Como fazer crédito habitação?</b></SectionTitle>
          </Stack>
          <Grid container my={8} spacing={4}>
            <Grid item xs={12} md={4}>
              <Stack alignItems="center" spacing={4}>
                <img src="/images/passo1-01.jpg" />
                <Typography variant="h5"><b>Preencher</b></Typography>
              </Stack>
            </Grid>
            <Grid item xs={12} md={4}>
              <Stack alignItems="center" spacing={4}>
                <img src="/images/passo2-01.jpg" />
                <Typography variant="h5"><b>Comparar</b></Typography>
              </Stack>
            </Grid>
            <Grid item xs={12} md={4}>
              <Stack alignItems="center" spacing={4}>
                <img src="/images/passo3-01.jpg" />
                <Typography variant="h5"><b>Escolher</b></Typography>
              </Stack>
            </Grid>
          </Grid>
          <Stack alignItems="center">
            <SectionTitle variant="h4" color="primary"><b>Já está!</b></SectionTitle>
          </Stack>
        </Container>
      </PageSection>
      <PageSection>
        <Container>
          <Stack alignItems="center">
            <SectionTitle variant="h4" color="primary"><b>Parceiros Oficiais</b></SectionTitle>
          </Stack>
          <Grid container mt={8} spacing={4} alignItems="center" justifyContent="center">
            <Grid item xs>
              <img src="/images/ctt.svg" width="250px" style={{ margin: 'auto', display: 'block' }} />
            </Grid>
            <Grid item xs>
              <img src="/images/santander.png" width="250px" style={{ margin: 'auto', display: 'block' }} />
            </Grid>
            <Grid item xs>
              <img src="/images/montepio.jpeg" width="250px" style={{ margin: 'auto', display: 'block' }} />
            </Grid>
            <Grid item xs>
              <img src="/images/caixa.png" width="250px" style={{ margin: 'auto', display: 'block' }} />
            </Grid>
          </Grid>
        </Container>
      </PageSection>
      <PageSection>
        <Container>
          <Stack alignItems="center">
            <SectionTitle variant="h4" color="primary"><b>Feedback</b></SectionTitle>
          </Stack>
          <Grid container mt={8} spacing={4} justifyContent="center">
            <Grid item xs>
              <Card elevation={4}>
                <CardContent>
                  <Stack spacing={4}>
                    <Stack direction="row" spacing={2} alignItems="center">
                      <ProfilePicture src="/images/profile/1.png" />
                      <Typography variant="h6"><b>Roberto Silva</b></Typography>
                    </Stack>
                    <Typography variant="body1">
                      Assertivos, profissionais, céleres, disponíveis desde o 1 contacto. Muito obrigado pelo vosso contributo na realização deste meu sonho!!!
                    </Typography>
                  </Stack>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs>
              <Card elevation={4}>
                <CardContent>
                  <Stack spacing={4}>
                    <Stack direction="row" spacing={2} alignItems="center">
                      <ProfilePicture src="/images/profile/2.png" />
                      <Typography variant="h6"><b>Andreia Gomes</b></Typography>
                    </Stack>
                    <Typography variant="body1">
                      Obrigado equipa de crédito habitação do sci.pt por me ajudarem neste processo! parte do sonho de ter casa própria, devo-o a vocês.
                    </Typography>
                  </Stack>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs>
              <Card elevation={4}>
                <CardContent>
                  <Stack spacing={4}>
                    <Stack direction="row" spacing={2} alignItems="center">
                      <ProfilePicture src="/images/profile/3.png" />
                      <Typography variant="h6"><b>Rui António</b></Typography>
                    </Stack>
                    <Typography variant="body1">
                      Rápidos no atendimento. Bom esclarecimento da minha dúvida. Profissionalismo e atenciosos. É claro que recomendo.
                    </Typography>
                  </Stack>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </PageSection>
      <PageSection>
        <Container>
          <Stack alignItems="center">
            <SectionTitle variant="h4" color="primary"><b>Blog</b></SectionTitle>
          </Stack>
          <News />
        </Container>
      </PageSection>
      <Footer />
    </>
  );
};

export default Homepage;
