import React, { useState } from 'react'
import { useQuery } from "@apollo/client";
import {
  Container, Stack,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
} from '@mui/material';
import Loading from 'shared/ui/loading';
import { GET_FAQ } from './queries';
import parseMarkdown from 'shared/utils/markdown';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Gap } from 'shared/ui/gap';
import { SectionTitle } from '../homepage/styled';

const Faq = (props) => {
  const { loading, data } = useQuery(GET_FAQ);
  const [expanded, setExpanded] = useState(false);

  const handleChange =
    (panel) => (event, isExpanded) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <Container sx={{ mt: 8 }}>
      <Stack alignItems="center">
        <SectionTitle variant="h3" color="primary"><b>Questões frequentes</b></SectionTitle>
      </Stack>
      <Gap />
      <Stack direction="row" flexWrap="wrap">
        {loading || !data ? <Loading /> : data.faq.map(faq => (
          <Accordion key={faq.id} square expanded={expanded === faq.id} elevation={0} onChange={handleChange(faq.id)} sx={{ flex: '100% 0 0' }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} >
              <Typography variant="h6" color={expanded === faq.id ? 'primary' : 'inherit'}>
                {faq.name}
              </Typography>
            </AccordionSummary>
            <AccordionDetails dangerouslySetInnerHTML={{ __html: parseMarkdown(faq.body) }} />
          </Accordion>
        ))}
      </Stack>
      <Gap />
    </Container>
  )
}

export default Faq