import { gql } from "@apollo/client";

export const GET_ARTICLE = gql`
  query GetArticle($id: String!) {
    getArticle(id: $id) {
      date
      id
      description
      mainImage {
        src
        title
      }
      title
      related {
        id
        title
        description
        date
        visible
        mainImage {
          src
          title
        }
      }
      sections {
        bodyType
        text
        images {
          src
          title
        }
      }    
    }
  }
`;
