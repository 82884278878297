import { Typography, Container, Stack, Grid } from "@mui/material";
import React from "react";

import { PageSection, SectionTitle } from "../homepage/styled";
import { SubHero } from "./styled";
import Footer from "../../partials/footer";

const AboutUs = (props) => {
  return (
    <>
      <SubHero />
      <PageSection>
        <Container>
          <Stack alignItems="center">
            <SectionTitle variant="h3" color="primary"><b>Sobre nós</b></SectionTitle>
          </Stack>
          <Typography variant="body1" mt={2} sx={{ textAlign: 'left' }}>
            <b>SCI - Sandra camelo Mediação Imobiliária</b>
            <br />
            <br />
            AMI:6650
            <br />
            <br />
            Criada em 2005, a SCI surge aliada à paixão desenvolvida pela área da mediação imobiliária, pelo contacto de pessoas para pessoas, pela constante adaptação e forte especialização num sector tão exigente e em constante evolução.
            <br />
            <br />
            A uma curta distância de uma das mais belas Capitais Europeias, a proximidade do mar e da serra, permitem-nos apreciar as vantagens de viver na Margem Sul.
            <br />
            <br />
            Na SCI, o compromisso é comum, trabalhamos diariamente com o objetivo de corresponder às exigências dos nossos clientes, prestando um acompanhamento de excelência!
          </Typography>
          <Grid container spacing={4} mt={8} alignItems="flex-start">
            <Grid item md={4} xs={12}>
              <Stack alignItems="center">
                <Typography mb={1} color="primary" variant="h4"><b>A Nossa Missão</b></Typography>
                <Typography variant="body1">Pretendemos ajudar as pessoas a encontrar aquilo que procuram. Queremos realizar sonhos e satisfazer vontades e tornar o capítulo da compra de casa numa experiência francamente positiva.</Typography>
              </Stack>
            </Grid>
            <Grid item md={4} xs={12}>
              <Stack alignItems="center">
                <Typography mb={1} color="primary" variant="h4"><b>Visão</b></Typography>
                <Typography variant="body1">Para satisfazer as necessidades de quem nos procura, atendendo ao que  é verdadeiramente importante, estamos sempre conscientes de quais os imóveis mais procurados. Assim, comprometo-nos a ser a referência inovadora do mercado imobiliário local.</Typography>
              </Stack>
            </Grid>
            <Grid item md={4} xs={12}>
              <Stack alignItems="center">
                <Typography mb={1} color="primary" variant="h4"><b>Valores</b></Typography>
                <Typography variant="body1">Guiamos-nos pela Confiança, pelo Compromisso e pela Concretização.</Typography>
              </Stack>
            </Grid>
          </Grid>
        </Container>
      </PageSection>
      <Footer />
    </>
  );
};

export default AboutUs;
