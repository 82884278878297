import * as React from "react";
import { colors } from "./colors";

const Loading = (props) => (
  <svg
    style={{
      margin: "auto",
      background: "0 0",
    }}
    width={75}
    height={75}
    viewBox="0 0 50 50"
    preserveAspectRatio="xMidYMid"
    display="block"
    {...props}
  >
    <circle cx={25} cy={25} r={0} fill="none" stroke={colors.primary} strokeWidth={2}>
      <animate
        attributeName="r"
        repeatCount="indefinite"
        dur="1.4925373134328357s"
        values="0;19"
        keyTimes="0;1"
        keySplines="0 0.2 0.8 1"
        calcMode="spline"
        begin="-0.7462686567164178s"
      />
      <animate
        attributeName="opacity"
        repeatCount="indefinite"
        dur="1.4925373134328357s"
        values="1;0"
        keyTimes="0;1"
        keySplines="0.2 0 0.8 1"
        calcMode="spline"
        begin="-0.7462686567164178s"
      />
    </circle>
    <circle cx={25} cy={25} r={0} fill="none" stroke={colors.midGray} strokeWidth={2}>
      <animate
        attributeName="r"
        repeatCount="indefinite"
        dur="1.4925373134328357s"
        values="0;19"
        keyTimes="0;1"
        keySplines="0 0.2 0.8 1"
        calcMode="spline"
      />
      <animate
        attributeName="opacity"
        repeatCount="indefinite"
        dur="1.4925373134328357s"
        values="1;0"
        keyTimes="0;1"
        keySplines="0.2 0 0.8 1"
        calcMode="spline"
      />
    </circle>
  </svg>
);

export default Loading;
