import styled, { css } from "styled-components";
import { Typography } from "@mui/material";

export const Hero = styled.section`
  min-height: 100vh;
  background-image: url(/images/hero-1.jpg);
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 150px 0;
  color: white;
  @media screen and (max-width: 768px) {
    background-position: center top;
    background-size: auto 700px;
    background-color: rgba(254, 254, 254, 1);
    min-height: auto;
    padding: 150px 0px 0;

  }
`

export const PageSection = styled.section`
  background: rgba(254, 254, 254, 1);
  padding: 48px 0;
  text-align:center;
  margin-top: 10px;
`

export const SectionTitle = styled(Typography)`
  padding: 0 24px;
  position: relative;
  z-index: 0;
  display: inline-flex;
  text-transform: uppercase;
  ${({disableLines}) => disableLines ? '' : css`
    &:before, &:after {
      content: '';
      position: absolute;
      background: rgba(0, 159, 152, 1);
      height: 2px;
      width: 250px;
      top: 50%;
      transform: translateY(-50%);
    }
    &:before {
      right: 100%;
    }
    &:after {
      left: 100%;
    }
  `}

`

export const ProfilePicture = styled.img`
  border-radius: 50%;
`