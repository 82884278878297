import React, { forwardRef } from 'react';
import { NumericFormat } from 'react-number-format';

export const NumberFormatCustom = forwardRef(function NumberFormatCustom(props, ref) {
  const { onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      allowLeadingZeros
      allowNegative={false}
      decimalScale={2}
      thousandSeparator
    />
  );
});
