import React from "react"

export const Logo = (props) => (
  <svg
    style={{ transition: 'all .2s ease-out' }}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="298.5556945800781 361.30999755859375 523.164306640625 421.2999267578125"
    {...props}
  >
    <defs>
      <linearGradient
        id="a"
        x1={408.96}
        x2={410.82}
        y1={651.16}
        y2={493.91}
        data-name="Gradiente sem nome 5"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.06} stopColor="#9d7b55" />
        <stop offset={1} stopColor="#bea48a" />
      </linearGradient>
      <linearGradient
        xlinkHref="#a"
        id="c"
        x1={592.93}
        x2={593.72}
        y1={650.65}
        y2={463.91}
        data-name="Gradiente sem nome 5"
      />
      <linearGradient
        xlinkHref="#a"
        id="d"
        x1={743.27}
        x2={741.16}
        y1={614.27}
        y2={455.27}
        data-name="Gradiente sem nome 5"
      />
      <linearGradient
        id="b"
        x1={768.85}
        x2={713.64}
        y1={419.28}
        y2={364.07}
        data-name="Gradiente sem nome 10"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.06} stopColor="#003e3a" />
        <stop offset={1} stopColor="#009f98" />
      </linearGradient>
      <linearGradient
        id="e"
        x1={756.42}
        x2={734.88}
        y1={377.23}
        y2={367.96}
        data-name="Gradiente sem nome 3"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#878787" />
        <stop offset={1} stopColor="#fff" />
      </linearGradient>
      <linearGradient
        xlinkHref="#b"
        id="f"
        x1={728.35}
        x2={728.35}
        y1={414.17}
        y2={384.85}
        data-name="Gradiente sem nome 10"
      />
      <linearGradient
        xlinkHref="#b"
        id="g"
        x1={750.58}
        x2={750.58}
        y1={414.17}
        y2={384.85}
        data-name="Gradiente sem nome 10"
      />
      <linearGradient
        xlinkHref="#b"
        id="h"
        x1={739.47}
        x2={739.47}
        y1={414.17}
        y2={384.85}
        data-name="Gradiente sem nome 10"
      />
      <linearGradient
        xlinkHref="#b"
        id="i"
        x1={299.52}
        x2={819.5}
        y1={614.65}
        y2={614.65}
        data-name="Gradiente sem nome 10"
        gradientTransform="matrix(.99953 .03071 -.02013 .85979 12.81 49.86)"
      />
    </defs>
    <g
      style={{
        isolation: "isolate",
      }}
    >
      <path
        d="M422.57 519.04c-17.46-4.59-31.26-8.22-31.26-19.36s10.64-15.08 20.59-15.08c11.89 0 21 5.21 27.87 15.9l42.42-24.49c-15.62-22.82-40.56-35.37-70.29-35.37-36.45 0-73.24 18.74-73.24 60.63s37.75 51.08 65.31 58.76c17.71 4.92 31.69 8.83 31.69 19.71 0 6.07-3.29 16.25-25.33 16.25-16.8 0-29.08-7.42-35.54-21.46l-43.74 25.25c14.72 27.2 41.38 40.99 79.27 40.99 6.23 0 12.53-.43 18.71-1.37l51.79-29.91c4.73-7.97 7.49-17.69 7.49-29.37 0-43.79-38-53.79-65.74-61.08"
        style={{
          fill: "url(#a)",
        }}
      />
      <path
        d="M599.99 589.75c-27.09 0-49.06-21.96-49.06-49.06s21.97-49.06 49.06-49.06c18.16 0 34.01 9.87 42.49 24.53l44.18-25.51c-17.3-29.9-49.63-50.02-86.67-50.02-55.26 0-100.07 44.79-100.07 100.06s44.81 100.07 100.07 100.07c37.04 0 69.37-20.12 86.67-50.02l-44.18-25.52c-8.48 14.66-24.34 24.53-42.49 24.53"
        style={{
          fill: "url(#c)",
        }}
      />
      <path
        d="M773.31 450.56v-3.77h-62.43V604.7l51.74 29.89v-.01l10.69 6.17v-4.35l-10.69-6.17V450.56h10.69z"
        style={{
          fill: "url(#d)",
        }}
      />
      <text
        style={{
          fill: "#fff",
          fontFamily: "Montserrat-ExtraBold,Montserrat",
          fontSize: "31.23px",
          fontWeight: 700,
        }}
        transform="translate(331.06 742.19)"
      >
        <tspan x={0} y={0}>
          {"SEU CR\xC9DITO IMOBILI\xC1RIO"}
        </tspan>
      </text>
      <text
        style={{
          fontFamily: "Montserrat-Light,Montserrat",
          fontSize: "18.54px",
          fontWeight: 300,
          letterSpacing: ".1em",
          fill: "#fff",
        }}
        transform="translate(436.59 777.07)"
      >
        <tspan x={0} y={0}>
          {"A REALIZAR SONHOS!"}
        </tspan>
      </text>
      <path
        d="M739.47 361.31c-15.79 0-28.59 12.79-28.59 28.59s12.8 28.59 28.59 28.59 28.59-12.8 28.59-28.59-12.8-28.59-28.59-28.59Zm0 49.67c-11.65 0-21.09-9.44-21.09-21.08s9.44-21.08 21.09-21.08 21.08 9.43 21.08 21.08-9.44 21.08-21.08 21.08Z"
        style={{
          fill: "url(#b)",
        }}
      />
      <path
        d="M760.19 370.21c-5.2-5.48-12.57-8.9-20.73-8.9-5.38 0-10.41 1.49-14.7 4.07v9.41c3.8-3.7 8.98-5.97 14.7-5.97 11.64 0 21.08 9.43 21.08 21.08 0 0 3.06-10.92-.36-19.68Z"
        style={{
          fill: "url(#e)",
          mixBlendMode: "multiply",
        }}
      />
      <path
        d="M732.67 389.23v17.33a18.057 18.057 0 0 1-8.64-7.4v-9.93h8.64Z"
        style={{
          fill: "url(#f)",
        }}
      />
      <path
        d="M754.9 389.23v9.92a18.024 18.024 0 0 1-8.64 7.41v-17.33h8.64Z"
        style={{
          fill: "url(#g)",
        }}
      />
      <path
        d="M743.79 377.86v29.5c-1.39.34-2.83.52-4.32.52s-2.94-.18-4.32-.52v-29.5h8.64Z"
        style={{
          fill: "url(#h)",
        }}
      />
      <path
        d="m821.72 508.56-94.29-2.38 9.07 21.93 1.4 3.21c-48.33 26.68-104.21 53.83-149.19 70.71-134.95 50.65-260.35 68.35-280.07 39.53-6.76-9.88.13-23.6 18.11-39.38-27.18 19.29-33.73 37.33-23.84 52.41 30.52 46.56 157.28 35.49 287.88-13.52 52.15-19.57 113.22-46.89 160.88-78.07l11.16 25.66 58.89-80.1Z"
        style={{
          fill: "url(#i)",
        }}
      />
    </g>
  </svg>
)

export const LogoNoText = ({ color, ...props }) => (
  <svg
    style={{ transition: 'all .2s ease-out' }}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="298.5556945800781 361.30999755859375 523.164306640625 322.94677734375"
    {...props}
  >
    <defs>
      <linearGradient
        id="a"
        x1={408.96}
        x2={410.82}
        y1={651.16}
        y2={493.91}
        data-name="Gradiente sem nome 5"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.06} stopColor="#9d7b55" />
        <stop offset={1} stopColor="#bea48a" />
      </linearGradient>
      <linearGradient
        xlinkHref="#a"
        id="c"
        x1={592.93}
        x2={593.72}
        y1={650.65}
        y2={463.91}
        data-name="Gradiente sem nome 5"
      />
      <linearGradient
        xlinkHref="#a"
        id="d"
        x1={743.27}
        x2={741.16}
        y1={614.27}
        y2={455.27}
        data-name="Gradiente sem nome 5"
      />
      <linearGradient
        id="b"
        x1={768.85}
        x2={713.64}
        y1={419.28}
        y2={364.07}
        data-name="Gradiente sem nome 10"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.06} stopColor="#003e3a" />
        <stop offset={1} stopColor="#009f98" />
      </linearGradient>
      <linearGradient
        id="e"
        x1={756.42}
        x2={734.88}
        y1={377.23}
        y2={367.96}
        data-name="Gradiente sem nome 3"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#878787" />
        <stop offset={1} stopColor="#fff" />
      </linearGradient>
      <linearGradient
        xlinkHref="#b"
        id="f"
        x1={728.35}
        x2={728.35}
        y1={414.17}
        y2={384.85}
        data-name="Gradiente sem nome 10"
      />
      <linearGradient
        xlinkHref="#b"
        id="g"
        x1={750.58}
        x2={750.58}
        y1={414.17}
        y2={384.85}
        data-name="Gradiente sem nome 10"
      />
      <linearGradient
        xlinkHref="#b"
        id="h"
        x1={739.47}
        x2={739.47}
        y1={414.17}
        y2={384.85}
        data-name="Gradiente sem nome 10"
      />
      <linearGradient
        xlinkHref="#b"
        id="i"
        x1={299.52}
        x2={819.5}
        y1={614.65}
        y2={614.65}
        data-name="Gradiente sem nome 10"
        gradientTransform="matrix(.99953 .03071 -.02013 .85979 12.81 49.86)"
      />
    </defs>
    <g
      style={{
        isolation: "isolate",
      }}
    >
      <path
        d="M422.57 519.04c-17.46-4.59-31.26-8.22-31.26-19.36s10.64-15.08 20.59-15.08c11.89 0 21 5.21 27.87 15.9l42.42-24.49c-15.62-22.82-40.56-35.37-70.29-35.37-36.45 0-73.24 18.74-73.24 60.63s37.75 51.08 65.31 58.76c17.71 4.92 31.69 8.83 31.69 19.71 0 6.07-3.29 16.25-25.33 16.25-16.8 0-29.08-7.42-35.54-21.46l-43.74 25.25c14.72 27.2 41.38 40.99 79.27 40.99 6.23 0 12.53-.43 18.71-1.37l51.79-29.91c4.73-7.97 7.49-17.69 7.49-29.37 0-43.79-38-53.79-65.74-61.08"
        style={{
          fill: "url(#a)",
        }}
      />
      <path
        d="M599.99 589.75c-27.09 0-49.06-21.96-49.06-49.06s21.97-49.06 49.06-49.06c18.16 0 34.01 9.87 42.49 24.53l44.18-25.51c-17.3-29.9-49.63-50.02-86.67-50.02-55.26 0-100.07 44.79-100.07 100.06s44.81 100.07 100.07 100.07c37.04 0 69.37-20.12 86.67-50.02l-44.18-25.52c-8.48 14.66-24.34 24.53-42.49 24.53"
        style={{
          fill: "url(#c)",
        }}
      />
      <path
        d="M773.31 450.56v-3.77h-62.43V604.7l51.74 29.89v-.01l10.69 6.17v-4.35l-10.69-6.17V450.56h10.69z"
        style={{
          fill: "url(#d)",
        }}
      />
      <path
        d="M739.47 361.31c-15.79 0-28.59 12.79-28.59 28.59s12.8 28.59 28.59 28.59 28.59-12.8 28.59-28.59-12.8-28.59-28.59-28.59Zm0 49.67c-11.65 0-21.09-9.44-21.09-21.08s9.44-21.08 21.09-21.08 21.08 9.43 21.08 21.08-9.44 21.08-21.08 21.08Z"
        style={{
          fill: "url(#b)",
        }}
      />
      <path
        d="M760.19 370.21c-5.2-5.48-12.57-8.9-20.73-8.9-5.38 0-10.41 1.49-14.7 4.07v9.41c3.8-3.7 8.98-5.97 14.7-5.97 11.64 0 21.08 9.43 21.08 21.08 0 0 3.06-10.92-.36-19.68Z"
        style={{
          fill: "url(#e)",
          mixBlendMode: "multiply",
        }}
      />
      <path
        d="M732.67 389.23v17.33a18.057 18.057 0 0 1-8.64-7.4v-9.93h8.64Z"
        style={{
          fill: "url(#f)",
        }}
      />
      <path
        d="M754.9 389.23v9.92a18.024 18.024 0 0 1-8.64 7.41v-17.33h8.64Z"
        style={{
          fill: "url(#g)",
        }}
      />
      <path
        d="M743.79 377.86v29.5c-1.39.34-2.83.52-4.32.52s-2.94-.18-4.32-.52v-29.5h8.64Z"
        style={{
          fill: "url(#h)",
        }}
      />
      <path
        d="m821.72 508.56-94.29-2.38 9.07 21.93 1.4 3.21c-48.33 26.68-104.21 53.83-149.19 70.71-134.95 50.65-260.35 68.35-280.07 39.53-6.76-9.88.13-23.6 18.11-39.38-27.18 19.29-33.73 37.33-23.84 52.41 30.52 46.56 157.28 35.49 287.88-13.52 52.15-19.57 113.22-46.89 160.88-78.07l11.16 25.66 58.89-80.1Z"
        style={{
          fill: "url(#i)",
        }}
      />
    </g>
  </svg>
)
