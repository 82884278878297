import * as React from "react"

const VariableRateIcon = (props) => (
  <svg
    width={19}
    height={19}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15.721 16.274H2.726v-2.758c.079.011.16.02.253.02.502 0 1.175-.2 1.802-1.034.534-.711.97-1.314 1.354-1.845.878-1.213 1.57-2.172 1.86-2.14.506.052.988.908 1.416 1.663.586 1.036 1.249 2.208 2.443 2.276 1.3.07 2.49-1.167 3.742-3.906a.682.682 0 0 0-1.238-.567c-1.343 2.941-2.194 3.132-2.425 3.113-.453-.025-.922-.856-1.336-1.586-.589-1.042-1.256-2.222-2.464-2.347-1.068-.11-1.665.711-3.102 2.695-.381.526-.815 1.123-1.34 1.827-.3.4-.597.556-.88.46-.03-.01-.058-.027-.085-.04V1.362a1.363 1.363 0 0 0-2.726 0v16.274C0 18.39.61 19 1.363 19H15.72a1.363 1.363 0 0 0 0-2.726ZM16.79 3.205c-.832 0-1.445.633-1.445 1.412 0 .78.613 1.412 1.444 1.412.824 0 1.437-.632 1.437-1.412 0-.78-.613-1.412-1.436-1.412Zm-.009 1.87c-.256 0-.441-.199-.441-.458 0-.256.182-.458.441-.458.268 0 .45.2.45.458a.448.448 0 0 1-.45.458ZM13.617 5.34l3.753-4.16-.548-.316-3.753 4.151.548.324ZM13.824 2.824c.823 0 1.436-.633 1.436-1.412C15.26.605 14.647 0 13.824 0c-.831 0-1.445.632-1.445 1.412 0 .78.614 1.412 1.445 1.412Zm-.008-1.87c.267 0 .45.199.45.458a.45.45 0 0 1-.45.458c-.256 0-.442-.2-.442-.458 0-.26.186-.458.442-.458Z"
      fill="#009F98"
    />
  </svg>
)

export default VariableRateIcon
