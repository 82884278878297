import React, { useEffect, useState } from "react";
import { Link, useRouteMatch } from "react-router-dom";
import { LogoNoText } from "shared/ui/logo";
import Menu from "./menu";
import {
  LogoHolder,
  StyledHeader,
} from "./styled";

const Header = (props) => {
  const [isScrolledDown, setScrolledDown] = useState(false);
  const match = useRouteMatch('/')

  const checkScrollTop = () => {
    if (window.scrollY > 40) {
      setScrolledDown(true);
    } else if (window.scrollY <= 40) {
      setScrolledDown(false);
    }
  };

  useEffect(() => {
    checkScrollTop();
    window.addEventListener("scroll", checkScrollTop);
    return () => window.removeEventListener("scroll", checkScrollTop);
  }, []);

  return (
    <StyledHeader $scrolledDown={isScrolledDown || !match.isExact}>
      <Menu scrolledDown={isScrolledDown || !match.isExact} />
      <LogoHolder>
        <Link to={"/"}>
          <LogoNoText
            //color="white"
            width={isScrolledDown || !match.isExact ? 103 : 159}
            height={isScrolledDown || !match.isExact ? 45 : 70}
          />
        </Link>
      </LogoHolder>
    </StyledHeader>
  );
};

export default Header;
