import React from "react";
import GlobalStyles from "../globalStyles";
import { Switch, Route } from "react-router-dom";
import Header from "./partials/header";
import Helmet from "react-helmet";
import BackToTop from "shared/ui/back-to-top";
import { routes } from "shared/routes";
import { createTheme, ThemeProvider } from "@mui/material";
import { SnackbarProvider } from "notistack";
import { useRenderedOnClient } from "shared/hooks/use-rendered-on-client";
import { themeOptions } from "shared/ui/theme-options";

const theme = createTheme(themeOptions);

const App = () => {
  const isRenderedOnClient = useRenderedOnClient()
  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider maxSnack={3}>
        <GlobalStyles />
        <Helmet titleTemplate="SCI - %s">
          <html lang="en" />
          <link rel="preconnect" href="https://fonts.gstatic.com" />
          <title>Faça a sua simulação de crédito habitação, e descubra o seu potencial de compra agora!</title>
          <meta name="description" content="Encontre a casa dos seus sonhos com os nossos especialistas em crédito habitação. Compre a sua casa hoje e use a nossa ferramenta de simulação de empréstimo para obter as melhores taxas. Trabalhamos com bancos líderes, como o Millenium, Santander, CGD e mais!" />
          <link rel="apple-touch-icon" sizes="180x180" href="/icons/apple-touch-icon.png" />
          <link rel="icon" type="image/png" sizes="32x32" href="/icons/favicon-32x32.png" />
          <link rel="icon" type="image/png" sizes="16x16" href="/icons/favicon-16x16.png" />
          <link rel="manifest" href="/icons/site.webmanifest" />
          <link rel="mask-icon" href="/icons/safari-pinned-tab.svg" color="#9a7c56" />
          <meta name="msapplication-TileColor" content="#da532c" />
          <meta name="theme-color" content="#ffffff" />
          <link
            rel="stylesheet"
            href="//fonts.googleapis.com/css?family=Open+Sans%3A200%2C300%2C400%2C400i%2C500%2C600%2C700%7CMerriweather%3A300%2C300i%7CMaterial+Icons"
            type="text/css"
            media="all"
          />
        </Helmet>
        <Header />
        <Switch>
          {routes.filter(route => !route.disabled).map((route) => (
            (isRenderedOnClient || route.ssr) && <Route key={route.path} {...route} />
          ))}
        </Switch>
        <BackToTop />
        <script type="text/javascript" dangerouslySetInnerHTML={{ __html: `
        var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
        (function(){
          var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
        s1.async=true;
        s1.src='https://embed.tawk.to/63be987a47425128790cd7dd/1gmg878cc';
        s1.charset='UTF-8';
        s1.setAttribute('crossorigin','*');
        s0.parentNode.insertBefore(s1,s0);
        })();
        <!--End of Tawk.to Script-->
        `}} />
        <script type="text/javascript" dangerouslySetInnerHTML={{ __html: `
          <!-- Facebook Pixel Code -->
          !function(f,b,e,v,n,t,s)
          {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)}(window,document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');
          fbq('init', '874932100321110'); 
          fbq('track', 'PageView');
          <!-- End Facebook Pixel Code -->
          `}} />

          <script async src="https://www.googletagmanager.com/gtag/js?id=G-LRF7QVM4R1" />
          <script type="text/javascript" dangerouslySetInnerHTML={{ __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
      
            gtag('config', 'G-LRF7QVM4R1');
          `}} />
      </SnackbarProvider>
    </ThemeProvider>
  );
};

export default App;
