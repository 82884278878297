import Simulate from "../app/pages/simulate";
import Homepage from "../app/pages/homepage";
import Imt from "../app/pages/imt";
import AboutUs from "../app/pages/about";
import Article from "../app/pages/article";
import Blog from "../app/pages/blog";
import Contacts from "../app/pages/contacts";

export const routes = [
  {
    path: "/",
    exact: true,
    component: Homepage,
    navBar: false,
    ssr: true,
    name: "Home",
  },
  {
    path: "/contacts",
    exact: true,
    component: Contacts,
    navBar: false,
    ssr: true,
    name: "Contactos",
  },
  {
    path: "/about",
    exact: true,
    component: AboutUs,
    navBar: false,
    ssr: true,
    name: "Sobre nós",
  },
  {
    path: "/article/:id",
    exact: true,
    component: Article,
    navBar: false,
    ssr: true,
    name: "Artigo",
  },
  {
    path: "/blog",
    exact: true,
    component: Blog,
    navBar: false,
    ssr: true,
    name: "Blog",
  },
  {
    path: "/simulate",
    exact: true,
    component: Simulate,
    navBar: false,
    ssr: false,
    name: "Simulate",
  },
  {
    path: "/imt",
    exact: true,
    component: Imt,
    navBar: false,
    ssr: false,
    name: "IMT",
  },
];
