import { useMutation } from '@apollo/client';
import LoadingButton from '@mui/lab/LoadingButton';
import { Stack, TextField, Typography } from "@mui/material";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { useSnackbar } from "notistack";
import React, { useMemo, useState } from "react";
import { formatCurrency } from "shared/formatters/currency";
import { themeOptions } from "shared/ui/theme-options";
import { validateNIF } from "shared/utils/validate-nif";
import { CONTACT_REQUEST } from "./mutations";

const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/
const inputStyle = {
  fontSize: '20px',
}

const PersonalisedPropositionForm = (props) => {
  const { offer, propertyValue, loanDuration, loanPercentage } = props
  const [requestContact, result] = useMutation(CONTACT_REQUEST)
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [nif, setNif] = useState('')
  const isEmailValid = emailRegex.test(email)
  const isNifValid = validateNIF(nif)
  const isValid = name !== '' && email !== '' && isEmailValid && nif !== '' && isNifValid
  const { enqueueSnackbar } = useSnackbar()
  const message = useMemo(() => `Pedido de proposta personalizada!
NIF Cliente: ${nif}

Detalhes oferta
Nome Oferta: ${offer.name}
Banco: ${offer.provider.name}
Valor imóvel: ${formatCurrency(propertyValue, 'EUR')}
Duração crédito(meses): ${loanDuration}
Percentagem de financiamento: ${loanPercentage * 100}%
Spread: ${offer.spread}%
Euribor: ${offer.euribor}%
Prestação mensal: ${formatCurrency(offer.installment, 'EUR')}
MTIC: ${formatCurrency(offer.mtic, 'EUR')}`, [offer, nif, propertyValue, loanDuration, loanPercentage])

  const handleSubmit = (e) => {
    e.preventDefault()
    e.stopPropagation()
    if (isValid) {
      requestContact({
        variables: {
          contactRequestInput: {
            name,
            email,
            message
          }
        },
        onError(err) {
          enqueueSnackbar('Algo correu mal, tente novamente mais tarde.', { variant: 'error' })
        }
      })
    }
  }
  
  return <ThemeProvider theme={createTheme({ ...themeOptions, palette: { ...themeOptions.palette, mode: 'dark' }})}>
  {
    result.called && result.data
    ? <Typography variant="h6" sx={{ color: 'white' }}>Obrigado pelo seu pedido, responderemos o mais brevemente possível.</Typography>
    : (
      <form onSubmit={handleSubmit}>
          <Stack spacing={6} sx={{ maxWidth: 600 }}>
            <Typography sx={{ color: 'white' }}>Preencha por favor os seguintes campos para podermos negociar as melhores condições para o seu crédito.</Typography>
            <TextField InputProps={{ sx: inputStyle }} required color="secondary" variant="standard" placeholder="Nome" value={name} onChange={(e) => setName(e.target.value)} />
            <TextField
              InputProps={{ sx: inputStyle }}
              required
              type="email"
              color="secondary"
              variant="standard"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              helperText={email !== '' && !isEmailValid ? "O Email está incorrecto" : ''}
            />
            <TextField
              InputProps={{ sx: inputStyle }}
              required
              color="secondary"
              variant="standard"
              placeholder="NIF"
              value={nif}
              onChange={(e) => setNif(e.target.value)}
              helperText={nif !== '' && !isNifValid ? "O NIF está incorrecto" : ''}
            />
            <LoadingButton loading={result.loading} disabled={!isValid} color="secondary" type="submit" variant="outlined">Pedir proposta personalizada</LoadingButton>
          </Stack>
        </form>
      )
    }
  </ThemeProvider>
};

export default PersonalisedPropositionForm;
