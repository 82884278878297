import { Typography, Stack } from '@mui/material'
import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import FacebookIcon from 'shared/ui/icons/facebook'
import InstagramIcon from 'shared/ui/icons/instagram'
import WhatsAppIcon from 'shared/ui/icons/whatsapp'
import { MenuButton, MenuButtonIcon, MenuNav, MenuBackdrop } from './styled'

const Menu = props => {
  const [isOpen, setOpen] = useState(false)

  return <>
    <MenuButton onClick={() => setOpen(v => !v)}>
      <MenuButtonIcon $isOpen={isOpen} />
    </MenuButton>
    <MenuBackdrop $isOpen={isOpen} onClick={() => setOpen(false)} />
    <MenuNav $isOpen={isOpen}>
      <Stack spacing={5} alignItems="flex-start">
        <NavLink onClick={() => setOpen(false)} exact to="/"><Typography variant="h4">Início</Typography></NavLink>
        <NavLink onClick={() => setOpen(false)} exact to="/simulate"><Typography variant="h4">Simulação</Typography></NavLink>
        <NavLink onClick={() => setOpen(false)} exact to="/imt"><Typography variant="h4">Simulador de IMT</Typography></NavLink>
        <NavLink onClick={() => setOpen(false)} exact to="/about"><Typography variant="h4">Sobre Nós</Typography></NavLink>
        <NavLink onClick={() => setOpen(false)} exact to="/blog"><Typography variant="h4">Blog</Typography></NavLink>
        <NavLink onClick={() => setOpen(false)} exact to="/contacts"><Typography variant="h4">Contactos</Typography></NavLink>
        <Stack direction="row" spacing={4}>
          <a target='_blank' href="https://wa.me/351915503118">
            <WhatsAppIcon />
          </a>
          <a target="_blank" href="https://www.instagram.com/sciimobiliaria/">
            <InstagramIcon />
          </a>
          <a target='_blank' href="https://www.facebook.com/SCI.Imobiliaria">
            <FacebookIcon />
          </a>
        </Stack>
      </Stack>
    </MenuNav>
  </>
}

export default Menu