import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { colors } from "./colors";

const Arrow = styled.button`
  display: block;
  position: fixed;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: white;
  box-shadow: 1px 1px 3px 0px rgba(0, 0, 0, 0.15);
  bottom: 20px;
  right: 20px;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  border: 1px solid ${colors.lightGray};
  cursor: pointer;
  transform: translateY(${({ $visible }) => ($visible ? "0" : "70px")});
  transition: all 0.2s ease-out;
  :hover {
    transform: translateY(-4px);
  }
  :after {
    content: "";
    top: 50%;
    left: 50%;
    transform: translate(-50%, -25%) rotate(45deg);
    width: 25%;
    height: 25%;
    position: absolute;
    border: 2px solid ${colors.midGray};
    border-right: none;
    border-bottom: none;
  }
`;

const BackToTop = () => {
  const [showScroll, setShowScroll] = useState(false);

  const checkScrollTop = () => {
    if (window.pageYOffset > 400) {
      setShowScroll(true);
    } else if (window.pageYOffset <= 400) {
      setShowScroll(false);
    }
  };

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    checkScrollTop();
    window.addEventListener("scroll", checkScrollTop);
    return () => window.removeEventListener("scroll", checkScrollTop);
  }, []);

  return <Arrow onClick={scrollTop} $visible={showScroll} />;
};

export default BackToTop;
