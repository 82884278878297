import { Button, Container, Grid, InputAdornment, MenuItem, Select, Stack, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { propertyLocationOptions, propertyUsageOptions } from "shared/constants/imt";
import { NumberFormatCustom } from "shared/forms/number-format";
import { getUrlVars, setUrlVars } from "shared/url";
import Footer from "../../partials/footer";
import { PageSection, SectionTitle } from "../homepage/styled";
import ImtRateResult from "./ImtRateResult";
import ImtRateTable from "./ImtRateTable";
import { SubHero } from "./styled";

const sanitizeOption = (option, optionList) => {
  const selectedOption = optionList.find(opt => opt.id === option) || optionList[0]

  return selectedOption.id
}

const Imt = (props) => {
  const urlVars = getUrlVars()
  const [propertyValue, setPropertyValue] = useState(parseFloat(urlVars.propertyValue) || 100000)
  const [addedValue, setAddedValue] = useState(parseFloat(urlVars.addedValue) || 0)
  const [purpose, setPurpose] = useState(sanitizeOption(urlVars.purpose, propertyUsageOptions))
  const [location, setLocation] = useState(sanitizeOption(urlVars.location, propertyLocationOptions))

  const [currentState, setCurrentState] = useState({
    propertyValue,
    addedValue,
  })

  useEffect(() => {
    setUrlVars({
      ...currentState,
      purpose,
      location,
    })
  }, [currentState, purpose, location])

  const handleCalculate = (e) => {
    e.preventDefault()
    setCurrentState({
      propertyValue: parseFloat(propertyValue) || 0,
      addedValue: parseFloat(addedValue) || 0,
    })
  }

  return (
    <>
      <SubHero />
      <PageSection>
        <Container>
          <Stack alignItems="center">
            <SectionTitle variant="h3" color="primary"><b>Simulador de IMT</b></SectionTitle>
          </Stack>
          <Typography variant="h6" color="primary" mt={4} sx={{ textTransform: 'uppercase' }}><b>Cálculo do Imposto Municipal sobre a taxa onerosa de imóveis (IMT)</b></Typography>
          <form onSubmit={handleCalculate}>
            <Grid container spacing={4} mt={4} alignItems="flex-start">
              <Grid container item md={6} xs={12} spacing={2} justifyContent="center">
                <Grid item xs={12}>
                  <Typography align="left" variant="h5" color="primary" mb={1}><b>Localização do imóvel</b></Typography>
                </Grid>
                <Grid item xs={12}>
                  <Select align="left" fullWidth value={location} onChange={(e) => setLocation(e.target.value)}>
                    {propertyLocationOptions.map(opt => (
                      <MenuItem key={opt.id} value={opt.id}>{opt.name}</MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item xs={12}>
                  <Select align="left" fullWidth value={purpose} onChange={(e) => setPurpose(e.target.value)}>
                    {propertyUsageOptions.map(opt => (
                      <MenuItem key={opt.id} value={opt.id}>{opt.name}</MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item xs={12}>
                  <Typography align="left" variant="body1">O IMT vai incidir sobre o valor constante do contrato ou sobre o valor patrimonial tributário (VPT) dos imóveis, consoante o que for maior.</Typography>
                </Grid>
                <Grid item xs={6} alignSelf="flex-end">
                  <Stack alignItems="flex-start">
                    <Typography variant="h6" color="primary" mb={1} textAlign="left"><b>Preço do imóvel</b></Typography>
                    <TextField
                      InputProps={{
                        endAdornment: <InputAdornment position="end">€</InputAdornment>,
                        inputComponent: NumberFormatCustom,
                      }}
                      fullWidth
                      value={propertyValue}
                      onChange={(e) => setPropertyValue(parseFloat(e.target.value))}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={6}>
                  <Stack alignItems="flex-start">
                    <Typography variant="h6" color="primary" mb={1} textAlign="left"><b>Valores complementares</b></Typography>
                    <TextField
                      InputProps={{
                        endAdornment: <InputAdornment position="end">€</InputAdornment>,
                        inputComponent: NumberFormatCustom,
                      }}
                      fullWidth
                      value={addedValue}
                      onChange={(e) => setAddedValue(parseFloat(e.target.value))}
                    />
                  </Stack>
                </Grid>
                <Grid item xs>
                  <Button disabled={isNaN(propertyValue) || isNaN(addedValue)} type="submit" variant="contained" color="primary" size="large">Calcular</Button>
                </Grid>
              </Grid>
              <Grid item md={6} xs={12}>
                <ImtRateResult 
                  {...currentState}
                  purpose={purpose}
                  location={location}
                />
              </Grid>
            </Grid>
          </form>
        </Container>
      </PageSection>
      <ImtRateTable
        location={location}
        purpose={purpose}
      />
      <Footer />
    </>
  );
};

export default Imt;
