import { Typography, Stack, TextField } from "@mui/material";
import React, { useState } from "react";
import { CONTACT_REQUEST } from "./mutations";
import { useMutation } from '@apollo/client'
import { useSnackbar } from "notistack";
import LoadingButton from '@mui/lab/LoadingButton';

const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/


export const ContactForm = props => {
  const inputStyle = {
    color: props.darkText ? 'inherit' : 'white',
    fontSize: '20px',
  }
  const [requestContact, result] = useMutation(CONTACT_REQUEST)
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')
  const isValid = name !== '' && email !== '' && message !== '' && emailRegex.test(email)
  const { enqueueSnackbar } = useSnackbar()

  const handleSubmit = (e) => {
    e.preventDefault()
    e.stopPropagation()
    if (isValid) {
      requestContact({
        variables: {
          contactRequestInput: {
            name,
            email,
            message
          }
        },
        onError(err) {
          enqueueSnackbar('Algo correu mal, tente novamente mais tarde.', { variant: 'error' })
        }
      })
    }
  }
  return result.called && result.data
      ? <Typography variant="h6">Obrigado pelo seu contacto, responderemos assim que possível.</Typography>
      : 
      <form onSubmit={handleSubmit}>
        <Stack spacing={8} sx={{ maxWidth: 490 }}>
          <TextField required color={props.darkText ? 'primary' : "secondary"} InputProps={{ sx: inputStyle }} variant="standard" placeholder="nome" value={name} onChange={(e) => setName(e.target.value)} />
          <TextField required color={props.darkText ? 'primary' : "secondary"} InputProps={{ sx: inputStyle }} variant="standard" placeholder="email" value={email} type="email"  onChange={(e) => setEmail(e.target.value)} />
          <TextField required color={props.darkText ? 'primary' : "secondary"} InputProps={{ sx: { ...inputStyle, minHeight: 100 } }} variant="standard" placeholder="em que podemos ajudar?" value={message} onChange={(e) => setMessage(e.target.value)} multiline />
          <LoadingButton loading={result.loading} disabled={!isValid} color={props.darkText ? 'primary' : "secondary"} type="submit" variant="outlined">Enviar</LoadingButton>
        </Stack>
      </form>
}