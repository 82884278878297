import styled, { createGlobalStyle, css } from "styled-components";

export const StyledHeader = styled.header`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  transition: all 0.2s ease-out;
  height:  ${({$scrolledDown}) => $scrolledDown ? '80px' : '124px'};
  background: ${({$scrolledDown}) => $scrolledDown ? 'rgba(0, 159, 152, 0.9)' : 'transparent'};
  z-index: 5;
  padding: 0 24px;
  @media screen and (max-width: 768px) {
    justify-content: flex-start;
  }
  &:after {
    content: '';
    position: absolute;
    width: calc(100% - 80px);
    height: 2px;
    left: 40px;
    bottom: 0;
    background: white;
    opacity: ${({$scrolledDown}) => $scrolledDown ? '0' : '1'};
    transition: all .2s ease-out;
  }
`;
export const LogoHolder = styled.div`
  padding: 18px 0;
`;

export const MenuButton = styled.button`
  all: unset;
  width: auto;
  padding: 16px;
  position: absolute;
  left: 24px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 10;
  @media screen and (max-width: 768px) {
    right: 24px;
    left: auto;
  }

`

export const MenuButtonIcon = styled.i`
  background: #fff;
  height: 3px;
  width: 45px;
  position: relative;
  display: block;
  margin: 15px 0 16px;
  transition: all .2s ease-out;

  &:before, 
  &:after{
    background: #fff;
    content: '';
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    transition: all .2s ease-out;
    transform-origin: 50% 50%;
  } 
  &:before {
    transform: translateY(-7px);
    width: calc(100% + 5px);
  }
  &:after {
    transform: translateY(7px);
    width: calc(100% - 5px);
  }

  ${({ $isOpen }) => $isOpen ? `
    background:none;
    &:before, &:after {width: 100%;}
    &:before {transform: rotate(45deg);}
    &:after {transform: rotate(-45deg);}
  ` : ''}
`

export const MenuNav = styled.nav`
  position: fixed;
  top: 0;
  background: #009F98;
  width: 50%;
  height: 100vh;
  overflow: auto;
  z-index: 5;
  padding: 150px 40px 40px 40px;
  opacity: ${({ $isOpen }) => $isOpen ? 1 : 0};
  left: ${({ $isOpen }) => $isOpen ? 0 : '-50%'};
  transition: all .2s ease-out;
  a {
    color: white;
    border-bottom: 2px solid transparent;
    padding: 8px 0

    h4 {
      font-weight: 300;
      text-transform: uppercase;
    }
  }
  a.active,
  a:hover {
    border-bottom-color: white;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
    left: ${({ $isOpen }) => $isOpen ? 0 : '100%'};
  }
`


export const MenuBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(37, 40, 42, 0.7);
  width: 100%;
  height: 100vh;
  z-index: 4;
  opacity: ${({ $isOpen }) => $isOpen ? 1 : 0};
  pointer-events: ${({ $isOpen }) => $isOpen ? 'all' : 'none'};
  transition: all .2s ease-out;
`

