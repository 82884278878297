import { colors } from "shared/ui/colors";
import styled from "styled-components";

export const Root = styled.section`
  background: ${colors.primary};
  color: white;
  padding: 120px 0 80px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
`
