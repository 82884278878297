import React from 'react'
import { useQuery } from "@apollo/client";
import { GET_IMT_QUOTE } from './queries';
import { Root } from './styled';
import { Typography, Stack } from '@mui/material';
import { formatCurrency } from 'shared/formatters/currency';
import { Box } from '@mui/material';

const ImtRateResultLine = props => {
  return (
    <Box sx={theme => ({ padding: theme.spacing(1, 2), borderRadius: '80px', width: '100%', background: 'white' })}>
      <Typography>
        {props.loading
          ? '...'
          : formatCurrency(props.value, 'EUR')
        }
      </Typography>
    </Box>
  )
}

const ImtRateResult = (props) => {
  const { purpose, location, propertyValue, addedValue } = props

  const { loading, error, data } = useQuery(GET_IMT_QUOTE, { variables: {
    imtQuoteInput: {
      purpose,
      location,
      propertyValue,
      addedValue,
    }
  }});

  return (
    <Root>
      <Stack alignItems="flex-start">
        <Typography variant="h5" color="primary" mb={1}><b>Resultado da Simulação</b></Typography>
        <Typography mt={2} mb={0.5}>IMT a Pagar</Typography>
        <ImtRateResultLine loading={loading} value={data?.getImtQuote.imtQuote} />
        <Typography mt={2} mb={0.5}>Imposto de selo (0.8%)</Typography>
        <ImtRateResultLine loading={loading} value={data?.getImtQuote.tax} />
        <Typography mt={2} mb={0.5}>Valores complementares</Typography>
        <ImtRateResultLine loading={loading} value={data?.getImtQuote.addedValue} />
        <Typography mt={4} mb={1} variant="h5" color="primary"><b>Total a pagar</b></Typography>
        <ImtRateResultLine loading={loading} value={data?.getImtQuote.total} />
        <Typography variant="body2" mt={1}>Estes valores são meramente informativos e não dispensam a consulta ou apoio de profissionais especializados.</Typography>
      </Stack>
    </Root>
  )
}

export default ImtRateResult