export const propertyLocationOptions = [
  { id: 'continent', name: 'Portugal Continental' },
  { id: 'isles', name: 'Regiões autónomas' }
]

export const propertyUsageOptions = [
  { id: 'permanentProperty', name: 'Habitação própria e permanente' },
  { id: 'secondProperty', name: 'Habitação secundária ou arrendamento' },
  { id: 'rusticBuildings', name: 'Prédios rústicos' },
  { id: 'others', name: 'Outros prédios urbanos e outras aquisições onerosas' },
]