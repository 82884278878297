export const themeOptions = {
  typography: {
    fontFamily: [
      'Gilroy',
      'Open Sans',
      'Helvetica',
      'Arial',
      'sans-serif'
    ].join(','),
  },
  palette: {
    primary: {
      main: "#009F98",
      contrastText: "#fff",
    },
    secondary: {
      main: "#fff",
      contrastText: "#000",
    },
  },
  components: {
    MuiDataGrid: {
      styleOverrides: {
        root: {
          border: 'none',
          '& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus, & .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-columnHeader:focus':
            {
              outline: 'none',
            },
        },
        cell: {
          padding: '16px 8px',
          border: 'none',
        },
        columnSeparator: {
          visibility: 'hidden',
        },
        columnHeaders: {
          background: 'rgba(0, 159, 152, 1)',
          color: '#fff',
          borderRadius: 10,
        },
        columnHeaderTitle: {
          fontWeight: 600,
        },
        row: {
          background: '#fff!important',
          borderRadius: 10,
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: 80,
        }
      }
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: '32px 40px',
          '.MuiCardMedia-root + &': {
            padding: '24px 40px 40px'
          },
        },
      }
    },
    MuiButton: {
      variants: [{
        props: {size: 'small'},
        style: {
          borderRadius: '10px',
          padding: '8px',
          minWidth: 'auto',
        }
      }],
      styleOverrides: {
        root: {
          borderRadius: 80,
          fontWeight: 700,
          padding: '8px 24px',
          minWidth: 200
        }
      }
    },
    MuiSlider: {
      styleOverrides: {
        track: {
          height: 10,
        },
        rail: {
          height: 10,
        },
        thumb: {
          width: 30,
          height: 30,
        }
      }
    },
  }
}