import React from "react";
import { hydrateRoot } from 'react-dom/client';
import App from "./app";
import { BrowserRouter } from "react-router-dom";
import { apolloClient } from "./app/graphql";
import { ApolloProvider } from "@apollo/client";
import createEmotionCache from "./app/createEmotionCache";
import { CacheProvider } from '@emotion/react';

const cache = createEmotionCache();

hydrateRoot(
  document.getElementById("root"),
  <BrowserRouter>
    <CacheProvider value={cache}>
      <ApolloProvider client={apolloClient}>
        <App />
      </ApolloProvider>
    </CacheProvider>
  </BrowserRouter>
);
