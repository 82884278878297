import * as React from "react"

const WhatsAppIcon = ({ color = '#fff', ...rest }) => (
  <svg
    width={30}
    height={30}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M14.805 6.08c-4.805 0-8.711 3.958-8.718 8.826 0 1.668.463 3.295 1.335 4.694l.205.335-.879 3.255 3.298-.878.318.195a8.602 8.602 0 0 0 4.435 1.232h.006c4.799 0 8.81-3.958 8.81-8.826 0-2.357-1.004-4.574-2.65-6.241-1.652-1.674-3.833-2.592-6.16-2.592Zm5.122 12.616c-.218.623-1.262 1.186-1.764 1.26-.833.127-1.48.06-3.14-.664-2.624-1.151-4.342-3.83-4.474-4.004-.133-.174-1.071-1.44-1.071-2.746 0-1.305.674-1.948.919-2.216a.948.948 0 0 1 .7-.335c.172 0 .35 0 .503.007.158.007.376-.06.588.455.218.53.74 1.835.806 1.969.066.134.113.288.02.462-.502 1.018-1.038.978-.767 1.446 1.012 1.761 2.023 2.37 3.563 3.154.264.134.416.114.568-.067.152-.174.655-.776.826-1.038.172-.267.35-.22.589-.134.238.088 1.527.73 1.79.864.265.134.437.201.503.308.06.128.06.663-.159 1.28ZM26.438 0H3.174C1.42 0 0 1.44 0 3.214v23.572C0 28.56 1.421 30 3.173 30h23.265c1.751 0 3.172-1.44 3.172-3.214V3.214C29.61 1.44 28.19 0 26.438 0ZM14.8 25.527c-1.758 0-3.484-.449-5.01-1.293l-5.559 1.48 1.487-5.504a10.689 10.689 0 0 1-1.401-5.31C4.323 9.047 9.022 4.286 14.799 4.286c2.802 0 5.433 1.105 7.415 3.114 1.977 2.008 3.166 4.674 3.166 7.513 0 5.853-4.805 10.614-10.581 10.614Z"
      fill={color}
    />
  </svg>
)

export default WhatsAppIcon
