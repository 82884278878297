import { gql } from "@apollo/client";

export const GET_IMT_RATES = gql`
  query GetImtRates($imtRatesInput: ImtRatesInput!) {
    getImtRates(imtRatesInput: $imtRatesInput) {
      limit
      tax
      parcelToRebate
    }
  }
`;

export const GET_IMT_QUOTE = gql`
  query GetImtQuote($imtQuoteInput: ImtQuoteInput!) {
    getImtQuote(imtQuoteInput: $imtQuoteInput) {
      imtQuote
      tax
      addedValue
      total
    }
  }
`;
