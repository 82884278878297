import React from 'react'
import { useQuery } from "@apollo/client";

import { GET_BANKS } from "./queries";
import { useEffect } from "react";
import { Stack } from "@mui/material";
import Loading from 'shared/ui/loading';
import styled from 'styled-components';

const BankIcon = styled.button`
  all: unset;
  box-sizing: border-box;
  cursor: pointer;
  max-width: calc((100% - 32px) / 3);
  width: 100%;
  padding: 5px 40px;
  border: 1px solid #b0b0b0;
  border-radius: 8px;
  opacity: ${({ $selected }) => $selected ? 1 : 0.4};
  
  &:hover {
    opacity: 1;
    border: 1px solid: #808080;
  }
  @media screen and (max-width: 1200px) {
    max-width: calc((100% - 16px) / 2);
  }

  img {
    width: 100%;
  }
`

export const BankSelector = props => {
  const { setSelectedBank, selectedBank } = props
  const { loading, data } = useQuery(GET_BANKS);

  useEffect(() => {})

  if (loading || !data) return <Loading />
  return (
    <Stack mt={2} spacing={2} direction="row" flexWrap="wrap" useFlexGap>
      {data.banks.map(bank => (
        <BankIcon key={bank.id} $selected={!selectedBank || selectedBank === bank.id} onClick={() => setSelectedBank(bank.id)} >
          <img src={bank.mainImage.src} alt={bank.name}/>
        </BankIcon>
      ))}
    </Stack>
  )
}