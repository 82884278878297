import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 400;
    src: url(/fonts/Gilroy-Light.otf) format('opentype');
  }
  @font-face {
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 600;
    src: url(/fonts/Gilroy-ExtraBold.otf) format('opentype');
  }
  body {
    margin: 0;
  	padding: 0;
    font-family: 'Gilroy', 'Open Sans', 'Helvetica', 'Arial', sans-serif;
    font-variant-ligatures: common-ligatures;
    min-height: 100vh;
  }
  *, *:before, *:after { box-sizing: border-box; }
  b { font-weight: 600; }
  #root {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-end;
    min-height: 100vh;
    overflow-x: hidden;
  }
  a { text-decoration: none; }
`;

export default GlobalStyle;
