import { Container, Grid, Typography, Stack } from "@mui/material";
import React from "react";
import FacebookIcon from "shared/ui/icons/facebook";
import InstagramIcon from "shared/ui/icons/instagram";
import WhatsAppIcon from "shared/ui/icons/whatsapp";
import { StyledFooter } from "./styled";

const Footer = (props) => {
  return (
    <StyledFooter>
      <Container>
        <Grid container alignItems="center" spacing={4}>
          <Grid item md={4}>
            <Typography variant="h3"><b>Sinta-se já em casa</b></Typography>
          </Grid>
          <Grid item md={4}>
            <Typography variant="h6">
              <b>Contactos</b>
            </Typography>
            <Typography variant="body2" sx={{ whiteSpace: "pre-line" }}>
              {`Telf. (+351) 212 071 774
Telm. (+351) 913 032 080
Horário 2ª a 6ª das 09h às 18h

Sede: Avenida de Belverde, 01 - 2845-483 Belverde

Azeitão: Rua de Lisboa, 369 R/C Loja - 2925-561 Azeitão

Serpa : Av. Simon Bolívar, 40, R/C Esq - 7830-361 Serpa`}
            </Typography>
          </Grid>
          <Grid item md={4}>
            <Stack spacing={2}>
              <Typography variant="h6"><b>Siga-nos nas redes sociais</b></Typography>
              <Stack direction="row" spacing={2}>
                <a target='_blank' href="https://wa.me/351915503118">
                  <WhatsAppIcon />
                </a>
                <a target="_blank" href="https://www.instagram.com/sciimobiliaria/">
                  <InstagramIcon />
                </a>
                <a target='_blank' href="https://www.facebook.com/SCI.Imobiliaria">
                  <FacebookIcon />
                </a>
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </StyledFooter>
  );
};

export default Footer;
