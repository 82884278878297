import { prop, propOr } from "ramda";
import { colors } from "shared/ui/colors";
import styled from "styled-components";

export const SubHero = styled.section`
  min-height: 450px;
  background-image: url(/images/hero-2.png);
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: cover;
`

export const OffersSection = styled.section`
  background: rgba(227, 225, 225, 1);
  padding: 48px 0;
  overflow: hidden;
`

export const OfferTableHeader = styled.div`
  border-radius: 10px;
  background: ${colors.primary};
  color: white;
  display: flex;
  align-items: center;
  z-index: 100;
`

export const OfferTableCell = styled.div`
  flex: ${propOr('auto', 'width')} ${propOr('0', 'grow')} 0;
  text-align: center;
  padding: 16px;
  font-weight: 700;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`

export const OfferTableRow = styled.div`
  border-radius: 10px;
  background: white;
  margin: 8px 0;
  color: black;
  overflow: hidden;
`
