import styled from "styled-components";
import { PageSection } from "../homepage/styled";

export const SubHero = styled.section`
  min-height: 700px;
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: cover;
`

export const Root = styled.div`
  background: rgba(155, 124, 86, 0.25);
  border-radius: 80px;
  padding: 40px;
  text-align: left;
  margin: auto;
  box-shadow: 2px 5px 10px rgba(0, 0, 0, 0.15);
`

export const NewsSection = styled(PageSection)`
  text-align: left;
`