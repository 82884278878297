import { gql } from "@apollo/client";

export const GET_ARTICLES = gql`
  query GetArticles {
    getArticles {
      date
      id
      mainImage {
        src
        title
      }
      title
      description
    }
  }
`;
