import React from "react";
import { Typography, Container, Stack, Card, CardContent, CardMedia, CardActionArea, useMediaQuery, useTheme } from "@mui/material";

import { Link } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { GET_ARTICLES } from "./queries";
import { PageSection, SectionTitle } from "../homepage/styled";
import parseMarkdown from "shared/utils/markdown";
import { useRenderedOnClient } from "shared/hooks/use-rendered-on-client";
import Loading from "shared/ui/loading";
import Footer from "../../partials/footer";
import { getViewport } from "shared/utils/getViewport";

const Blog = (props) => {
  const { data, loading } = useQuery(GET_ARTICLES)
  const theme = useTheme()
  const isRenderedOnClient = useRenderedOnClient()
  const matches = useMediaQuery(theme.breakpoints.up('sm'))

  const articles = data?.getArticles || []
  return (
    <>
      <PageSection>
        <Container sx={{ pt: 8 }} maxWidth="md">
          <SectionTitle variant="h3" color="primary"><b>Últimas notícias do mercado imobiliário</b></SectionTitle>
          <Stack mt={5}>
            <Stack spacing={3} justifyContent="center">
              {loading ? <Loading /> : null}
              {articles.map(article => (
                <Card elevation={2}>
                  <CardActionArea component={Link} to={`/article/${article.id}`}>
                    <Stack direction={matches || !isRenderedOnClient ? "row-reverse" : 'column'} alignItems="stretch">
                      <CardMedia
                        component="img"
                        alt={article.mainImage.title}
                        sx={matches || !isRenderedOnClient ? { maxWidth: '270px', borderRadius: '80px' } : {}}
                        image={getViewport('300x300', article.mainImage.src)}
                      />
                      <div />
                      <CardContent sx={matches || !isRenderedOnClient ? { px: 6, py: 5 } : {}}>
                        <Typography textAlign="left" variant="h5" component="div">
                          <b>{article.title}</b>
                        </Typography>
                        <Typography textAlign="left" gutterBottom variant="subtitle1" color="text.secondary">
                          {article.date}
                        </Typography>
                        <Typography textAlign="left" variant="body2" color="text.secondary">
                          <div dangerouslySetInnerHTML={{ __html: parseMarkdown(article.description) }}/>
                        </Typography>
                      </CardContent>
                    </Stack>
                  </CardActionArea>
                </Card>
              ))}
            </Stack>
          </Stack>
        </Container>
      </PageSection>
      <Footer />
    </>
  );
};

export default Blog;
