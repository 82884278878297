import { Typography, Container, Stack, Grid, Slider, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { formatCurrency } from "shared/formatters/currency";
import { getUrlVars, setUrlVars } from "shared/url";
import { PageSection, SectionTitle } from "../homepage/styled";
import OfferTable from "./OfferTable";
import { SubHero } from "./styled";
import Footer from "../../partials/footer";
import Faq from "./Faq";
import { ContactForm } from "../contacts/ContactForm";
import { BankSelector } from "./BankSelector";

const Simulate = (props) => {
  const urlVars = getUrlVars()

  const [propertyValue, setPropertyValue] = useState(parseInt(urlVars.propertyValue) || 100000)
  const [loanPercentage, setLoanPercentage] = useState(parseFloat(urlVars.loanPercentage) || 0.2)
  const [loanDuration, setLoanDuration] = useState(parseInt(urlVars.loanDuration) || 30)
  const [selectedBank, setSelectedBank] = useState(urlVars.selectedBank || '')

  useEffect(() => {
    setUrlVars({
      propertyValue,
      loanDuration,
      loanPercentage,
      selectedBank,
    })
  }, [propertyValue, loanDuration, loanPercentage, selectedBank])

  return (
    <>
      <SubHero />
      <div style={{ background: '#e3e1e1' }}>
        <PageSection style={{ maxWidth: '80%', margin: '-200px auto 0', }}>
          <Container>
            <Stack alignItems="center">
              <SectionTitle disableLines variant="h3" color="primary"><b>Simulador Crédito Habitação</b></SectionTitle>
            </Stack>
            <Grid container spacing={4}>
              <Grid item xs={1}></Grid>
              <Grid item xs={12} md={6} sx={{ textAlign: 'left' }}>
                <Typography variant="h4" color="primary" mt={4} sx={{ textTransform: 'uppercase' }}><b>Como vamos ajudar?</b></Typography>
                <Typography variant="body1" mt={2}>Simplificamos a procura das melhores condições para o seu crédito à habitação com o nosso simulador. Poupando a necessidade de passar por um processo demorado e burocrático na busca do crédito ideal para si!</Typography>
                <BankSelector selectedBank={selectedBank} setSelectedBank={setSelectedBank} />
              </Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={12} md={4}>
                <Grid container spacing={4} mt={4} alignItems="flex-end">
                  <Grid item xs={12}>
                    <Stack mb={1} alignItems="center">
                      <Typography color="primary" variant="h6"><b>Valor do imóvel</b></Typography>
                      <Typography variant="h6">{formatCurrency(propertyValue, 'EUR')}</Typography>
                    </Stack>
                    <TextField
                      defaultValue={propertyValue}
                      size="small"
                      fullWidth
                      inputProps={{ sx: { textAlign: 'center' } }}
                      onBlur={(e) => setPropertyValue(parseInt(e.target.value) || 100000)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Stack mb={1} alignItems="center">
                      <Typography color="primary" variant="h6"><b>Percentagem de financiamento</b></Typography>
                      <Typography variant="h6">{(loanPercentage * 100).toFixed(0)}%</Typography>
                    </Stack>
                    <Slider
                      value={loanPercentage}
                      min={0.1}
                      step={0.01}
                      max={0.9}
                      onChange={(e, value) => setLoanPercentage(parseFloat(value))}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Stack mb={1} alignItems="center">
                      <Typography color="primary" variant="h6"><b>Prazo do empréstimo</b></Typography>
                      <Typography variant="h6">{loanDuration} anos</Typography>
                    </Stack>
                    <Slider
                      value={loanDuration}
                      min={10}
                      step={1}
                      max={40}
                      onChange={(e, value) => setLoanDuration(parseInt(value))}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </PageSection>
      </div>
      <OfferTable
        loanDuration={loanDuration}
        propertyValue={propertyValue}
        loanPercentage={loanPercentage}
        selectedBank={selectedBank}
      />
      <Faq />
      <Container sx={{ my: 10 }}>
        <Grid container>
          <Grid xs={12} md={6}>
            <Typography variant="h3" color="primary"><b>Sinta-se já em casa</b></Typography>
            <Typography variant="h4">Entre em contacto connosco e nós tratamos do resto!</Typography>
          </Grid>
          <Grid xs={12} md={6}>
            <ContactForm darkText />
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </>
  );
};

export default Simulate;
