import { gql } from "@apollo/client";

export const GET_OFFERS = gql`
  query GetOffers($creditOptionsInput: CreditOptionsInput!) {
    getCreditOptions(creditOptionsInput: $creditOptionsInput) {
      id
      taeg
      tan
      isFixedRate
      name
      euribor
      spread
      mtic
      installment
      processingInstallmentFee
      dossierFee
      evaluationFee
      formalisationFee
      creditTax
      description
      provider {
        name
        mainImage {
          src
        }
      }
    }
  }
`;

export const GET_FAQ = gql`
  query GetFaq {
    faq {
      id
      name
      body
    }
  }
`;

export const GET_BANKS = gql`
  query GetBanks {
    banks {
      id
      name
      slug
      mainImage {
        title
        src
      }
    }
  }
`;
