import React from 'react'
import { GET_ARTICLES } from '../blog/queries'
import { useQuery } from "@apollo/client";
import { Typography, Stack, Card, CardContent, CardMedia, CardActionArea } from "@mui/material";
import { Link } from "react-router-dom";
import parseMarkdown from "shared/utils/markdown";
import Loading from "shared/ui/loading"
import { getViewport } from "shared/utils/getViewport";

const News = props => {
  const { data, loading } = useQuery(GET_ARTICLES)
  const articles = data?.getArticles || []

  return (
    <Stack mt={5}>
      <Stack spacing={3} direction={"row"} justifyContent="center">
        {loading ? <Loading /> : null}
        {articles.slice(0, 3).map(article => (
          <Card elevation={2} sx={{ display: 'flex', alignItems: 'stretch' }}>
            <CardActionArea component={Link} to={`/article/${article.id}`}>
              <Stack direction={'column'} alignItems="stretch">
                <CardMedia
                  component="img"
                  alt={article.mainImage.title}
                  image={getViewport('300x300', article.mainImage.src)}
                />
                <div />
                <CardContent>
                  <Typography textAlign="left" variant="h5" component="div">
                    <b>{article.title}</b>
                  </Typography>
                  <Typography textAlign="left" gutterBottom variant="subtitle1" color="text.secondary">
                    {article.date}
                  </Typography>
                  <Typography textAlign="left" variant="body2" color="text.secondary">
                    <div dangerouslySetInnerHTML={{ __html: parseMarkdown(article.description) }}/>
                  </Typography>
                </CardContent>
              </Stack>
            </CardActionArea>
          </Card>
        ))}
      </Stack>
    </Stack>
  )
}

export default News