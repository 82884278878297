import { Slider, Box, Typography, Stack, Button } from '@mui/material'
import React, { useState } from 'react'
import { formatCurrency } from 'shared/formatters/currency'
import { Root } from './styled'

const SimulatorWidget = props => {
  const [propertyValue, setPropertyValue] = useState(100000)
  const [loanPercentage, setLoanPercentage] = useState(0.2)
  const [loanDuration, setLoanDuration] = useState(30)

  const simulatorUrl = `/simulate?propertyValue=${propertyValue}&loanPercentage=${loanPercentage}&loanDuration=${loanDuration}`

  return <Root>
    <Stack spacing={3} mb={3}>
      <Typography variant="h4" textAlign="center"><b>Simulação rápida</b></Typography>
      <Box>
        <Stack direction="row" justifyContent="space-between" mb={1}>
          <Typography variant="h6">Valor do imóvel</Typography>
          <Typography variant="h6">{formatCurrency(propertyValue, 'EUR')}</Typography>
        </Stack>
        <Slider
          value={propertyValue}
          min={10000}
          step={1000}
          max={1000000}
          onChange={(e, value) => setPropertyValue(parseInt(value))}
        />
      </Box>
      <Box>
        <Stack direction="row" justifyContent="space-between" mb={1}>
          <Typography variant="h6">Percentagem de financiamento</Typography>
          <Typography variant="h6">{(loanPercentage * 100).toFixed(0)}%</Typography>
        </Stack>
        <Slider
          value={loanPercentage}
          min={0.1}
          step={0.01}
          max={0.9}
          onChange={(e, value) => setLoanPercentage(parseFloat(value))}
        />
      </Box>
      <Box>
        <Stack direction="row" justifyContent="space-between" mb={1}>
          <Typography variant="h6">Prazo do empréstimo</Typography>
          <Typography variant="h6">{loanDuration} anos</Typography>
        </Stack>
        <Slider
          value={loanDuration}
          min={10}
          step={1}
          max={40}
          onChange={(e, value) => setLoanDuration(parseInt(value))}
        />
      </Box>
    </Stack>
    <Button variant="contained" color="primary" size="large" href={simulatorUrl}>Simular</Button>
  </Root>
}

export default SimulatorWidget