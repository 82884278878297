import * as React from "react"

const FixedRateIcon = (props) => (
  <svg
    width={19}
    height={19}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16.389 16.274H2.84v-4.658h10.667c.392 0 .71-.305.71-.681 0-.376-.318-.681-.71-.681H2.84V1.363C2.841.61 2.205 0 1.421 0 .636 0 0 .61 0 1.363v16.274C0 18.39.636 19 1.42 19H16.39c.784 0 1.42-.61 1.42-1.363 0-.752-.636-1.363-1.42-1.363ZM14.195 5.34l3.913-4.16-.571-.316-3.913 4.154.571.321ZM17.503 3.205c-.867 0-1.506.633-1.506 1.412 0 .78.64 1.412 1.506 1.412.858 0 1.497-.632 1.497-1.412 0-.78-.64-1.412-1.497-1.412Zm-.009 1.87a.448.448 0 0 1-.46-.458c0-.256.19-.458.46-.458.279 0 .469.2.469.458 0 .243-.19.458-.469.458ZM14.411 2.824c.858 0 1.498-.633 1.498-1.412C15.909.605 15.269 0 14.41 0c-.866 0-1.506.632-1.506 1.412 0 .78.64 1.412 1.506 1.412Zm-.008-1.87c.278 0 .468.199.468.458a.46.46 0 0 1-.468.458.448.448 0 0 1-.46-.458c0-.26.193-.458.46-.458Z"
      fill="#009F98"
    />
  </svg>
)

export default FixedRateIcon
