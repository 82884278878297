import React, { useEffect, useState, useMemo } from 'react'
import { useLazyQuery } from "@apollo/client";
import { GET_OFFERS } from './queries';
import { OffersSection, OfferTableCell, OfferTableHeader, OfferTableRow } from './styled';
import { Button, Typography, Stack, Container, Grid, Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody
} from '@mui/material';
import { formatCurrency } from 'shared/formatters/currency';
import FixedRateIcon from './FixedRateIcon';
import VariableRateIcon from './VariableRateIcon';
import Loading from 'shared/ui/loading';
import { colors } from 'shared/ui/colors';
import parseMarkdown from 'shared/utils/markdown';
import { useBreakpoints } from 'shared/hooks/use-breakpoints';
import PersonalisedPropositionForm from './PersonalisedPropositionForm';

const OfferTable = (props) => {
  const [getOffers, { loading, data }] = useLazyQuery(GET_OFFERS);
  const [showMoreInfo, setShowMoreInfo] = useState(null)
  const [selectedOffer, setSelectedOffer] = useState(null)
  const { currentViewport } = useBreakpoints()

  const isBiggerTablet = ['xxl', 'xl', 'l'].indexOf(currentViewport) !== -1
  const isMobile = ['xs', 's'].indexOf(currentViewport) !== -1

  useEffect(() => {
    let tout = setTimeout(() => {
      getOffers({
        variables: {
          creditOptionsInput: {
            propertyValue: props.propertyValue,
            loanPercentage: props.loanPercentage,
            loanDuration: props.loanDuration,
            preferredBank: props.selectedBank,
          }
        },
      })
    }, 2000)
    return () => clearTimeout(tout)
  }, [props.propertyValue, props.loanPercentage, props.loanDuration, props.selectedBank])

  const showMoreInfoObject = data?.getCreditOptions.find(({ id }) => id === showMoreInfo)
  const selectedOfferObject = data?.getCreditOptions.find(({ id }) => id === selectedOffer)

  return <OffersSection>
    <Container>
      <Grid container>
        <Grid item xs={12}>
          {!isMobile && (
            <OfferTableHeader>
              <OfferTableCell width="150px" shrink="0">Entidade</OfferTableCell>
              <OfferTableCell width="120px" >TAEG</OfferTableCell>
              {isBiggerTablet && <OfferTableCell width="100px" shrink="0">Taxa</OfferTableCell>}
              <OfferTableCell width="20%" grow="1">Total Crédito (MTIC)</OfferTableCell>
              <OfferTableCell width="20%" grow="1">Prestação Mensal</OfferTableCell>
              {isBiggerTablet && <OfferTableCell width="230px" shrink="0"></OfferTableCell>}
            </OfferTableHeader>
          )}
          {loading || !data ? <Loading /> : null}
          {(data?.getCreditOptions || []).map(row =>
            <OfferTableRow>
              <Stack direction="row" alignItems={isMobile ? 'flex-start' : "stretch"} flexWrap={isMobile ? 'wrap' : 'nowrap'}>
                <OfferTableCell width={isMobile ? '100%' : "150px"}>
                  <img src={row.provider.mainImage.src} width="110" />
                </OfferTableCell>
                {isMobile
                  ? ['TAEG', 'MTIC', 'Prestação Mensal'].map(title => (
                    <OfferTableCell width="33%" style={{ paddingTop: '8px', paddingBottom: 0, fontWeight: 400 }}>
                      {title}
                    </OfferTableCell>
                  ))
                  : null
                }
                <OfferTableCell width={isMobile ? '33%' : "120px"}>
                  <Stack alignItems="center">
                    <Typography noWrap={false} variant="h6">
                      <b>{row.taeg.toFixed(2)}%</b>
                    </Typography>
                    <Typography noWrap>
                      TAN: {row.tan.toFixed(3)}%
                    </Typography>
                    {!isBiggerTablet && (
                      <Typography noWrap>
                        <b>Taxa {row.isFixedRate ? 'Fixa' : 'Variável'}</b>
                      </Typography>
                    )}
                  </Stack>
                </OfferTableCell>
                {isBiggerTablet && 
                  <OfferTableCell width="100px">
                    <Stack alignItems="center">
                      <Typography noWrap={false} variant="h6">
                        {row.isFixedRate ? <FixedRateIcon /> : <VariableRateIcon />}
                      </Typography>
                      <Typography>
                        {row.isFixedRate ? 'Fixa' : 'Variável'}
                      </Typography>
                    </Stack>
                  </OfferTableCell>
                }
                <OfferTableCell width={isMobile ? '33%' : "20%"} grow="1">
                  <Typography noWrap={false} variant="h6">
                    <b>{formatCurrency(row.mtic, 'EUR')}</b>
                  </Typography>
                </OfferTableCell>
                <OfferTableCell width={isMobile ? '33%' : "20%"} grow="1" style={{ background:  colors.alphaPrimary }}>
                  <Typography noWrap={false} variant="h6">
                    <b>{formatCurrency(row.installment + row.processingInstallmentFee, 'EUR')}</b>
                  </Typography>
                </OfferTableCell>
                {isBiggerTablet && 
                  <OfferTableCell width="230px">
                    <Button variant="contained" color="primary" size="small" onClick={() => setSelectedOffer(row.id)}>Proposta Personalizada</Button>
                  </OfferTableCell>
                }
              </Stack>
              <Stack direction="row" justifyContent="flex-end" spacing={2} sx={{
                borderTop: `1px solid ${colors.midLightGray}`,
                p: 2,
              }}>
                <Button variant="text" size="small" onClick={() => setShowMoreInfo(row.id)}>
                  Mais informações
                </Button>
                {!isBiggerTablet && <Button variant="contained" color="primary" size="small" onClick={() => setSelectedOffer(row.id)}>Proposta Personalizada</Button>}
              </Stack>
            </OfferTableRow>
          )}
        </Grid>
      </Grid>
    </Container>
    <Dialog open={!!showMoreInfoObject} onClose={() => setShowMoreInfo(null)}>
      {showMoreInfoObject && <>
        <DialogTitle>{showMoreInfoObject.provider.name}</DialogTitle>
        <DialogContent>
          <DialogContentText
            sx={{ whiteSpace: 'pre-line' }}
            dangerouslySetInnerHTML={{ __html: parseMarkdown(showMoreInfoObject.description) }}
          />
          <Table sx={{ minWidth: '100%' }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell><b>Taxas</b></TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell component="th" scope="row">
                  TAEG
                </TableCell>
                <TableCell align="right">{showMoreInfoObject.taeg.toFixed(2)}%</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Spread
                </TableCell>
                <TableCell align="right">{showMoreInfoObject.spread.toFixed(2)}%</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Euribor
                </TableCell>
                <TableCell align="right">{showMoreInfoObject.euribor.toFixed(2)}%</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  <b>Outros Custos</b>
                </TableCell>
                <TableCell align="right"></TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Imposto do Selo
                </TableCell>
                <TableCell align="right">{showMoreInfoObject.creditTax.toFixed(2)}€</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Processamento da Prestação
                </TableCell>
                <TableCell align="right">{showMoreInfoObject.processingInstallmentFee.toFixed(2)}€</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Comissão de Dossier
                </TableCell>
                <TableCell align="right">{showMoreInfoObject.dossierFee.toFixed(2)}€</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Comissão de Avaliação
                </TableCell>
                <TableCell align="right">{showMoreInfoObject.evaluationFee.toFixed(2)}€</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Comissão de Formalização
                </TableCell>
                <TableCell align="right">{showMoreInfoObject.formalisationFee.toFixed(2)}€</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <Button variant="text" size="small" onClick={() => setShowMoreInfo(null)}>Fechar</Button>
          <Button variant="contained" size="small" color="primary" onClick={() => setSelectedOffer(showMoreInfo)}>Proposta Personalizada</Button>
        </DialogActions>
      </>}
    </Dialog>
    <Dialog open={!!selectedOfferObject} onClose={() => setSelectedOffer(null)} PaperProps={{ sx: { background: colors.primary }}}>
      {selectedOfferObject && <>
        <DialogTitle sx={{ color: 'white', textAlign: 'center' }}><b>Proposta personalizada</b></DialogTitle>
        <DialogContent>
          <PersonalisedPropositionForm 
            offer={selectedOfferObject}
            propertyValue={props.propertyValue}
            loanPercentage={props.loanPercentage}
            loanDuration={props.loanDuration}
          />
        </DialogContent>
      </>}
    </Dialog>
  </OffersSection>
}

export default OfferTable