import styled from "styled-components";

export const Root = styled.div`
  background: white;
  border-radius: 80px;
  max-width: 400px;
  color: rgba(37, 40, 42, 1);
  padding: 40px 24px;
  text-align:center;
  margin: auto;
  box-shadow: 2px 5px 10px rgba(0, 0, 0, 0.15);
`