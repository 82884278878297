import React, { useEffect } from 'react'
import { useQuery } from "@apollo/client";
import { GET_IMT_RATES, GET_OFFERS } from './queries';
import { ImtRatesSection } from './styled';
import { Button, Typography, Stack, Container } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid'
import { F } from 'ramda';
import { formatCurrency } from 'shared/formatters/currency';

const columns = [
  {
    field: 'limit',
    headerName: 'Valor do imóvel',
    sortable: false,
    flex: 1,
    renderCell(params) {
      return (
        <div>
          <Typography noWrap={false} variant="body1">
            {params.row.previousLimit ? `mais de ${formatCurrency(params.row.previousLimit, 'EUR')} ` : ''}
            {params.value === -1 
              ? ''
              : `até ${formatCurrency(params.value, 'EUR')}`
            }
          </Typography>
        </div>
      )
    },
  },
  {
    field: 'tax',
    headerName: 'Taxa',
    headerAlign: 'center',
    align: 'center',
    sortable: false,
    flex: 1,
    valueFormatter: (params) => {
      if (params.value == null) {
        return '';
      }
      return `${params.value}%`;
    },
  },
  {
    field: 'parcelToRebate',
    headerName: 'Parcela a abater',
    headerAlign: 'center',
    align: 'center',
    sortable: false,
    flex: 1,
    renderCell(params) {
      return (
        <div>
          <Typography noWrap={false} variant="body1">
            {formatCurrency(params.row.parcelToRebate, 'EUR')}
          </Typography>
        </div>
      )
    },
  },
]

const ImtRateTable = (props) => {
  const { purpose, location } = props

  const { loading, error, data } = useQuery(GET_IMT_RATES, { variables: {
    imtRatesInput: {
      purpose,
      location,
    }
  }});

  return <ImtRatesSection>
    <Container maxWidth="md">
      <DataGrid
        density="compact"
        getRowId={(row) => row.limit}
        getRowSpacing={() => ({ top: 8, bottom: 8 })}
        columns={columns}
        autoHeight
        disableColumnMenu
        loading={loading || !data}
        rows={data?.getImtRates?.map((row, idx, arr) => ({
          ...row,
          previousLimit: idx === 0 ? null : arr[idx - 1].limit
        })) || []}
        isRowSelectable={F}
        isCellEditable={F}
        hideFooter
        getRowHeight={() => 'auto'}
      />
    </Container>
  </ImtRatesSection>
}

export default ImtRateTable