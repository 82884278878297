import styled from "styled-components";

export const SubHero = styled.section`
  min-height: 450px;
  background-image: url(/images/hero-3.jpg);
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: cover;
`

export const ImtRatesSection = styled.section`
  background: rgba(227, 225, 225, 1);
  padding: 48px 0;
`

export const Root = styled.div`
  background: rgba(0, 159, 152, 0.25);
  border-radius: 80px;
  padding: 40px;
  text-align: left;
  margin: auto;
  box-shadow: 2px 5px 10px rgba(0, 0, 0, 0.15);
`