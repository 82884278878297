import { Typography, Container, Stack, Grid, Link } from "@mui/material";
import React from "react";
import FacebookIcon from "shared/ui/icons/facebook";
import InstagramIcon from "shared/ui/icons/instagram";
import WhatsAppIcon from "shared/ui/icons/whatsapp";
import { Root } from "./styled";
import { ContactForm } from "./ContactForm";

const Contacts = (props) => {
  return (
    <>
      <Root>
        <Container>
        <Grid container alignItems="center" spacing={4}>
            <Grid item md={7}>
              <Stack spacing={4}>
                <Typography variant="h2"><b>Sinta-se já em casa</b></Typography>
                <Typography variant="h4">Entre em contacto connosco e nós tratamos do resto!</Typography>
                <ContactForm />
              </Stack>
            </Grid>
            <Grid item md={5}>
              <Stack spacing={4}>
                <Stack spacing={2}>
                  <Typography variant="h5"><b>Horário</b></Typography>
                  <Typography variant="body1">
                    2ª a 6ª das 09h às 18h
                  </Typography>
                </Stack>
                <Stack spacing={2}>
                  <Typography variant="h5">Sede <b>Belverde</b></Typography>
                  <Typography variant="body1">
                    Avenida de Belverde, 01
                    <br />
                    2845-483 Belverde
                    <br />
                    (+351) <Link color="inherit" href="tel:+351212071774">212 071 774</Link> | <Link color="inherit" href="tel:+351913032080">913 032 080</Link> | <Link color="inherit" href="tel:+351915503118">915 503 118</Link>
                    <br />
                    <Link color="inherit"  href="mailto:processual@scimob.pt">processual@scimob.pt</Link>
                  </Typography>
                  <Typography variant="h5">Loja <b>Azeitão</b></Typography>
                  <Typography variant="body1">
                    Rua de Lisboa, 369 R/C Loja
                    <br />
                    2925-561 Azeitão
                    <br />
                    (+351) <Link color="inherit" href="tel:+351212071774">212 071 774</Link> | <Link color="inherit" href="tel:+351913032080">913 032 080</Link> | <Link color="inherit" href="tel:+351915503118">915 503 118</Link>
                    <br />
                    <Link color="inherit"  href="mailto:processual@scimob.pt">processual@scimob.pt</Link>
                  </Typography>
                  <Typography variant="h5">Loja <b>Serpa</b></Typography>
                  <Typography variant="body1">
                    Av. Simon Bolívar, 40, R/C Esq
                    <br />
                    7830-361 Serpa
                    <br />
                    (+351) <Link color="inherit" href="tel:+351212071774">212 071 774</Link> | <Link color="inherit" href="tel:+351913032080">913 032 080</Link> | <Link color="inherit" href="tel:+351915503118">915 503 118</Link>
                    <br />
                    <Link color="inherit"  href="mailto:processual@scimob.pt">processual@scimob.pt</Link>
                  </Typography>
                </Stack>
                <Stack spacing={2}>
                  <Typography variant="h5"><b>Siga-nos nas redes sociais</b></Typography>
                  <Stack direction="row" spacing={2}>
                    <a target='_blank' href="https://wa.me/351915503118">
                      <WhatsAppIcon />
                    </a>
                    <a target="_blank" href="https://www.instagram.com/sciimobiliaria/">
                      <InstagramIcon />
                    </a>
                    <a target='_blank' href="https://www.facebook.com/SCI.Imobiliaria">
                      <FacebookIcon />
                    </a>
                  </Stack>
                </Stack>
              </Stack>
            </Grid>
          </Grid>
        </Container>
      </Root>
      <div />
    </>
  );
};

export default Contacts;
