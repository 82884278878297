import * as React from "react"

const FacebookIcon = ({ color = '#fff', ...rest}) => (
  <svg
    width={31}
    height={30}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M27.212 0H3.88A3.343 3.343 0 0 0 .545 3.333v23.334C.545 28.502 2.045 30 3.88 30h11.666V18.333h-3.333v-4.125h3.333v-3.416c0-3.607 2.02-6.14 6.277-6.14l3.005.003v4.342h-1.995c-1.657 0-2.287 1.243-2.287 2.396v2.817h4.28l-.946 4.123h-3.334V30h6.667a3.342 3.342 0 0 0 3.333-3.333V3.333C30.545 1.5 29.045 0 27.212 0Z"
      fill={color}
    />
  </svg>
)

export default FacebookIcon
