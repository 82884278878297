import * as React from "react"

const InstagramIcon = ({ color = '#fff', ...rest }) => (
  <svg
    width={31}
    height={30}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M27.212 0H3.88A3.343 3.343 0 0 0 .545 3.333v23.334C.545 28.502 2.045 30 3.88 30h23.333a3.342 3.342 0 0 0 3.333-3.333V3.333C30.545 1.5 29.045 0 27.212 0ZM15.52 24.205a9.232 9.232 0 0 0 9.23-9.232c0-.563-.072-1.106-.172-1.64h2.635V25.36a1.15 1.15 0 0 1-1.155 1.15H5.034a1.15 1.15 0 0 1-1.155-1.15V13.333H6.46a8.715 8.715 0 0 0-.173 1.64 9.233 9.233 0 0 0 9.232 9.232ZM9.75 14.973a5.768 5.768 0 1 1 11.536 0 5.768 5.768 0 0 1-11.536 0Zm16.307-6.64h-2.692a1.157 1.157 0 0 1-1.153-1.153V4.485c0-.637.517-1.152 1.152-1.152h2.691c.64 0 1.157.515 1.157 1.152v2.693c0 .635-.517 1.155-1.155 1.155Z"
      fill={color}
    />
  </svg>
)

export default InstagramIcon
