import React from "react";
import { Typography, Container, Stack, Card, CardContent, CardMedia, CardActionArea, Button } from "@mui/material";

import { NewsSection, SubHero } from "./styled";
import { Link, useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { GET_ARTICLE } from "./queries";
import { PageSection, SectionTitle } from "../homepage/styled";
import parseMarkdown from "shared/utils/markdown";
import Footer from "../../partials/footer";
import { getViewport } from "shared/utils/getViewport";
import { Helmet } from "react-helmet";

const Article = (props) => {
  const params = useParams()
  const { data, loading } = useQuery(GET_ARTICLE, { variables: { id: params.id }})
  if (loading || !data?.getArticle) return null
  const article = data.getArticle
  return (
    <>
      <Helmet>
        <title>{article.title}</title>
        <meta name="description" content={article.description} />
        <meta property="og:title" content={article.title} />
        <meta property="og:description" content={article.description} />
        <meta property="og:image" content={getViewport('1440x700', article.mainImage.src)} />
      </Helmet>
      <SubHero style={{ backgroundImage: `url("${getViewport('1440x700', article.mainImage.src)}")` }} />
      <NewsSection>
        <Container>
          <Stack spacing={2}>
            <div>
              <Button component={Link} to="/blog" size="small" variant="text" >Voltar ao Blog</Button>
            </div>
            <Typography variant="h3" mb={4}><b>{article.title}</b></Typography>
            <Typography variant="h6" mb={4} color="text.secondary">{article.date}</Typography>
          </Stack>
          <Stack spacing={4}>
            {article.sections.map(section => (
              section.bodyType === 'text'
                ? <Typography variant="body1"><div dangerouslySetInnerHTML={{ __html: parseMarkdown(section.text) }}/></Typography>
                : <Stack direction="row" justifyContent="center">{section.images.map(image => <img src={image.src} alt={image.title} />)}</Stack>
            ))}
          </Stack>
        </Container>
      </NewsSection>
      {article.related && article.related.length !== 0 && (
        <PageSection>
          <Container>
            <SectionTitle variant="h3" color="primary"><b>Artigos Relacionados</b></SectionTitle>
            <Stack mt={5}>
              <Stack spacing={3} justifyContent="center" direction="row">
                {article.related.map(relatedArticle => (
                  <Card elevation={2} sx={{ maxWidth: 'calc(100% / 3)', minWidth: '320px' }}>
                    <CardActionArea component={Link} to={`/article/${relatedArticle.id}`}>
                      <CardMedia
                        component="img"
                        alt={relatedArticle.mainImage.title}
                        height="270"
                        image={getViewport('300x300', relatedArticle.mainImage.src)}
                      />
                      <CardContent>
                        <Typography textAlign="left" variant="h5" component="div">
                          <b>{relatedArticle.title}</b>
                        </Typography>
                        <Typography textAlign="left" gutterBottom variant="subtitle1" color="text.secondary">
                          {relatedArticle.date}
                        </Typography>
                        <Typography textAlign="left" variant="body2" color="text.secondary">
                          <div dangerouslySetInnerHTML={{ __html: parseMarkdown(relatedArticle.description) }}/>
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                ))}
              </Stack>
            </Stack>
          </Container>
        </PageSection>
      )}
      <Footer />
    </>
  );
};

export default Article;
