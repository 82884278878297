import { gql } from "@apollo/client";

export const CONTACT_REQUEST = gql`
  mutation ContactRequest($contactRequestInput: ContactRequestInput!) {
    contactRequest(contactRequestInput: $contactRequestInput) {
      name
      email
      message
    }
  }
`;
