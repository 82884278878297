export const colors = {
  primary: "#009F98",
  alphaPrimary: "rgba(0, 159, 152, .4)",
  white: "#FFFFFF",
  black: "#000000",
  midGray: "#808080",
  midLightGray: "rgb(180, 180, 180)",
  lightGray: "rgb(235, 235, 235)",
  transparent: 'transparent'
};
